@tailwind base;
@tailwind components;
@tailwind utilities;

.web3modal-modal-lightbox {
  z-index: 100 !important;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(./assets/fonts/inter/Inter-Regular.ttf) format('ttf');
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(./assets/fonts/inter/Inter-Medium.ttf) format('ttf');
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'),
    url(./assets/fonts/inter/Inter-SemiBold.ttf) format('ttf');
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(./assets/fonts/inter/Inter-Bold.ttf) format('ttf');
}

#hyphen-widget .glassy-outline {
  box-shadow: 0 2px 1px hsla(0, 0%, 100%, 0.5) inset,
    0 -3px 1px hsla(250, 70%, 5%, 0.3) inset, 0 -2px 6px hsla(0, 0%, 100%, 0.25);
}

#hyphen-widget input[type='number']::-webkit-inner-spin-button,
#hyphen-widget input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

#hyphen-widget code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#hyphen-widget * {
  box-sizing: border-box;
}

#hyphen-widget .custom-tooltip {
  max-width: 160px !important;
  width: auto !important;
  font-size: 10px !important;
  line-height: 16px !important;
  padding: 4px 8px !important;
  border-radius: 4px !important;
  background-color: #374151 !important;
}
